@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;1,300&display=swap');

* {
    color: rgb(77, 70, 65);
    font-family: 'Lato', sans-serif;
}

body {
    background-color: #F8EDEB;
    overflow-x: hidden  ;
}

.basketViewContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.basketViewContainer h2 {
  font-size: 3rem;
}

.basketListContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.basketListElement {
  margin-top: 1rem;
  font-size: 1.2rem;
  width: 35rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.basketListElementName {
  /* Opcjonalne: Dostosowanie szerokości dla nazwy produktu */
  width: 50% /* lub inna wartość */
}

.basketListElement button {
  /* Zmienione style dla przycisków */
  background-color: #fdf6f4;
  width: 4rem;
  height: 4rem;
  border-radius: 10px;
  border: 1px solid rgb(175, 166, 166);
  box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
  cursor: pointer;
  font-size: 1.5rem;
  margin: 0 0.5rem; /* Poprawione marginesy */
}

.basketListQty{
  background-color: #faf5f4;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 10px;
  padding: 1.2rem;
  box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
  
}

.basketQtyControl {
  /* Dodane style dla kontenera kontrolera ilości */
  display: flex;
  align-items: center;
  width: 30%;
}

.basketPrice {
  display: flex;
}

.basketListElementName {
  margin-right: 2rem;
}

.header {
    margin: 0.2rem 0 -3.3rem 0rem;
    font-size: 1.5rem;
    color:rgb(92, 92, 92);
    display: flex;
    justify-content: space-between;
    background-color: #F8EDEB;
}

.header img {
    transform: scale(0.8);
    position: relative;
    left: -5rem;
    cursor: pointer;
    transition: 0.5s;
    height: 7rem;
    margin-left: 2rem;
}

.productWeight {
  font-size: 12px;
  position: relative;
  left: 0.4rem;
  top: 1px;
}

.basketContainer {
    position: absolute;
    display: flex;
    top: 2.2rem;
    right: 2.2rem;
    transform: scale(2);
    border: 1px solid rgb(92, 92, 92);
    border-radius: 10px;
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 1px 4px rgba(112, 110, 110, 0.8);
    transition: 0.5s;
    background-color: rgb(253, 248, 248);
}

.basketContainer:hover {
    background-color: rgb(187, 187, 187);
    transform: scale(2.1);
}

.basketList {
    transform: scale(0.3);
    position: absolute;
    top: 0px;
    background-color: pink;
    opacity: 50%;
}

.basketList:hover {
    opacity: 1;
}

.menuContainer {
    display: flex;
    justify-content: space-evenly;
    height: 3rem;
    align-items: center;
    margin: 4rem -0 1rem 0;
}

.menuLabel {
    background-color: #fff7f6;
    padding: 0.2rem;
    border: 0.1px solid rgb(173, 173, 173);
    border-top: 2.5px solid rgb(133, 133, 133);
    width: 100%;
    text-align: center;
    height: 2.2rem;
    padding: 1rem;
    align-items: center;
    color: rgb(59, 58, 55);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.menuLabel span {
    #fdf6f4-space: nowrap;
    position: relative;
    top: 0.3rem;
}

.menuLabel:hover {
    cursor: pointer;
}

.divisionView {
    position: absolute;
    display: none;
    top: 14rem;
    justify-content: center;
}

.divisions {
    justify-content: space-around;
    text-align: center;
    margin-bottom: 2.5rem;
}

.cardContainer {
    border: 1px solid rgb(182, 182, 182);
    border-radius: 1rem;
    margin: 1rem;
    width: 20rem;
    height: 32rem;
    text-align: center;
    display: inline-block;
    justify-content: center;
    background-color: rgb(247, 237, 233);
    box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
}

.flipCardFront img {
    width: 20rem;
    border-radius: 3%;
    box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
    
}

.noImage {
    position: absolute;
    left: 0;
    z-index: -1;
}

.productName {
    background-color: #fdf6f4;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    position: absolute;
    bottom: 3.5rem;
    min-height: 3rem;
    align-items: center;
    box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
    border-radius: 10px;
    
}

.productPrice {
    font-size: 1.5rem;
    position: absolute;
    right: 1.5rem;
    bottom: 0.9rem;
    font-weight: 600;
}

.productQty {
    position: absolute;
    left: 1.5rem;
    bottom: 0.8rem;
}

.flipCard {
    width: 100%;
    height: 80%;
    cursor: pointer;
    border-bottom: 1px solid rgb(182, 182, 182);
    border-radius: 10px;
    display: flex;
    background-color: rgb(245, 234, 228);
}
.flipCardInner {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
}

.flipCardFront, .flipCardBack {
    position: absolute;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
}

.flipCardFront:hover {
}

.flipCardFront::after {
    content: "Kliknij aby zobaczyć dodatkową zawartość";
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7);
    color: #fdf6f4;
    border-radius: 5px;
    width: 100%;
    height: 37.5%;
    padding-top: 9.5rem;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}
  
.flipCardFront:hover::after {
    opacity: 0.6;
}

.flipCardBack {
    transform: rotateY(180deg);
    background-size: cover;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
}

.button {
    background-color: #fdf6f4;
    width: 10rem;
    height: 4rem;
    border-radius: 10px;
    border: 1px solid rgb(175, 166, 166);
    box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
    font-size: 1.2rem;
    color:rgb(63, 57, 49);
    transition: 0.5s;
    cursor: pointer;
    position: absolute;
    margin-left: -16rem;
    margin-top: 28rem;
}

.button:hover {
    box-shadow: 0 2px 15px rgba(138, 134, 134, 0.8);
    transform: scale(1.02);
}

.buttonDisabled {
    width: 10rem;
    height: 4rem;
    border-radius: 10px;
    border: 1px solid rgb(175, 166, 166);
    box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
    font-size: 1.2rem;
    color:rgb(63, 57, 49);
    transition: 0.5s;
    margin-top: 1rem;
    position: absolute;
    margin-left: -16rem;
    margin-top: 28rem;
    background-color: rgb(214, 212, 212);
}

.descSection {
    margin: 10px 15px 0px 15px;
    display: flex;
    background-color: #fdf6f4;
    box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
    border-radius: 10px;
    padding: 10px;
    justify-content: center;
    font-size: 1.1rem;
}

.footerContainer {
    position:absolute;
    bottom: -10rem;
    width: 100vw;
    display: flex;
    min-height: 5rem;
    box-shadow: 0 -3px 3px rgba(146, 146, 146, 0.8);
}


.leftFooter {
    width: 50%;
    background-color: #595045;
    border-right: 1px solid rgb(143, 143, 143);
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: #fdf6f4;
}

.rightFooter {
    width: 50%;
    background-color: #595045;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: rgb(150, 150, 150);
}

.leftFooter a, .rightFooter a {
    width: 100%;
    text-decoration: none;
    text-align: center;
    margin: 5px;
    color: #fdf6f4;
}

.startPage {
    height: 1000px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 26rem;
}

.quoteContainer {
    width: 80%;
    text-align: center;
}

.quoteContainer q {
    font-style: italic;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.5rem;
}

.quoteContainer::after {
    content: '';
    display: block;
    border-bottom: 1px solid #8f8d8d; /* You can adjust the thickness and color of the line as needed */
    width: 60%;
    margin: auto;
    margin-top: 2rem;
  }

  .startPagePhotosContainer {
    display: flex;
    width: 80%;
    margin-top: 4rem;
    justify-content: center;
  }

  .photoLeft img, .photoRight img {
    height: 25rem;
    object-fit: cover;
    width: 40rem;
  }

  .photoLeft div, .photoRight div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .photoLeft div h3, .photoRight div h3 {
    font-size: 2rem;
  }

  .photoLeft div span, .photoRight div span {
    font-size: 1.3rem;
    font-weight: 400;
    font-style: italic;
    margin-top: -1rem;
  }

  .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .slider {
    width: 100%;
    margin: 0rem 0 0 0;
  }

  .sliderButton {
    background-color: #fdf6f4;
    width: 10rem;
    height: 4rem;
    border-radius: 10px;
    border: 1px solid rgb(175, 166, 166);
    box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
    font-size: 1.2rem;
    color:rgb(63, 57, 49);
    transition: 0.5s;
    cursor: pointer;
    margin-top: 1rem;
  }

  .sliderButton:hover {
    box-shadow: 0 2px 15px rgba(138, 134, 134, 0.8);
    transform: scale(1.02);
  }

  .sliderPhotoContainer img {
    object-fit: cover;
    width: 600px;
    height: 500px;
    position: relative;
    top: 2px;
    left: 2px;
    border: 2px solid rgb(255, 255, 255);
  }

  .nowosci {
    margin-top: 4rem;
    justify-content: flex-start;
  }

  .nowosci h1{
    font-size: 3.5rem;
    font-weight: 200;
  }

  .sliderCategory, .sliderQty {
    font-size: 1rem;
    margin-bottom: 5px;
    font-style: italic;
  }

  .sliderPrice {
    font-size: 1.8rem;
    margin: 1rem 0 1rem 0;
  }

  .about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about h1 {
    font-size: 3.5rem;
    margin-top: -0.5rem;
  }

  .about img {
    width: 60vw;
    border-radius: 15px;
    margin: 0rem 0 6rem 0;
  }

  .about span {
    font-size: 1.4rem; 
    font-weight: 500;
    line-height: 2rem;
    width: 60%;
    text-align: center;
    margin-bottom: 3rem;
  }

  .sliderContainer {
    display: flex;
    justify-content: space-evenly;
  }

  .sliderSubContainer {
    background-color: #f7f0ef;
    width: 100%;
    font-size: 2.5rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
  }

  .sliderLeft {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-110%, -50%);
    background-color: #fdf6f4;
    width: 4rem;
    height: 4rem;
    border-radius: 10px;
    border: 1px solid rgb(175, 166, 166);
    box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
    cursor: pointer;
    font-size: 1.5rem;
  }

  .sliderRight {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(110%, -50%);
    background-color: #fdf6f4;
    width: 4rem;
    height: 4rem;
    border-radius: 10px;
    border: 1px solid rgb(175, 166, 166);
    box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
    cursor: pointer;
    font-size: 1.5rem;
  }

  .menuIcon {
    display: none; /* Pokaż ikonę na wersji mobilnej */
    background-color: #fdf6f4;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  input, select, option, textarea {
    display: flex;
    margin: 1rem;
    height: 3rem;
    width: 20rem;
    text-align: center;
    border-radius: 10px;
    border: 1px solid rgb(233, 233, 233);
    box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
    background-color: #fdf6f4;
    font-size: 1rem;
  }

  textarea {
    padding-top: 1rem;
    min-height: 6rem;
  }

  .submit {
    background-color: #fdf6f4;
    width: 10rem;
    height: 4rem;
    border-radius: 10px;
    border: 1px solid rgb(175, 166, 166);
    box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
    font-size: 1.2rem;
    color:rgb(63, 57, 49);
    transition: 0.5s;
    cursor: pointer;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-bottom: 5rem;
    margin-top: 2rem;
  }

  .submit:hover {
    transform: scale(1.01);
  }
  

  @media screen and (max-width: 1080px) {
    
    .menuContainer {
        display: flex;
        flex-direction: column; /* Zmiana na układ pionowy */
        align-items: center;
        position: relative;
        top: 15rem;
      }

    .menuLabel {
        background-color: #f7f0ef;
        padding: 0.2rem;
        border: 0.1px solid rgb(173, 173, 173);
        border-top: 2.5px solid rgb(133, 133, 133);
        width: 100%;
        text-align: center;
        height: 2.2rem;
        padding: 1rem;
        color: rgb(59, 58, 55);
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        cursor: pointer; /* Wskaźnik na elementy klikalne */
        margin-bottom: 0.3rem; /* Odstęp między elementami menu */
      }

      .divisions {
        justify-content: space-around;
        text-align: center;
        margin-bottom: 2.5rem;
        margin-top: 10rem;
      }

      .about {
        margin-top: 6rem;
        width: 100%;
      }
      
      .about img {
        width: 400px;
      }
         
      .about span {
        width: 90%;
        font-size: 1.2rem;
      }

      .startPage {
        margin-top: 10rem;
        margin-bottom: 80rem;
      }

      .header img {
        transform: scale(0.5);
        position: absolute;
        left: -7rem;
      }

      .header {
        height: 4rem;
      }

      .startPagePhotosContainer {
        display: flex;
        flex-direction: column;
        width: 50%;
        justify-content: center;
        align-items: center;
      }
      
      .photoLeft div, .photoRight div {
        text-align: center;
        margin-bottom: 3rem;
      }

      .photoLeft img, .photoRight img {
        width: 90vw;
      }

      .quoteContainer {
        width: 100vw;
      }

      .quoteContainer q{
        font-size: 2rem;
      }

      .slider {
        width: 100%;
        background-color: #f7f0ef;
      }

      .sliderPhotoContainer {
        justify-content: center;
        display: flex;
      }

      .sliderPhotoContainer img {
        height: 20rem;
        width: 90%;
        margin-bottom: 2rem;
        margin-top: 1.5rem;
        object-fit:contain;
        border: none;      
      }

      .sliderContainer {
        flex-direction: column;
      }

      .sliderLeft {
        transform: translate(45%, 338%);
        z-index: 10;
      }

      .sliderRight {
        transform: translate(-45%, 338%);
        z-index: 10;
      }

      .sliderSubContainer {
        font-size: 1.2rem;
        justify-content: center;
        text-align: center;
      }

      .sliderButton {
        margin-bottom: 2rem;
      }

      .menuLabel{
        display: none; /* Ukryj listę na starcie */
        flex-direction: column;
      }

      .menuContainer.show {
        display: flex;
        flex-direction: column; /* Zmiana na układ pionowy */
        align-items: center;
        position: relative;
        top: 5rem;
      }
    
      .menuLabel.show {
        display: flex; /* Pokaż listę po kliknięciu na ikonę */
        position: relative;
        top: 0rem
      }
    
      .menuIcon {
        display: flex; /* Pokaż ikonę na wersji mobilnej */
        background-color: #fdf6f4;
        width: 100vw;
        padding: 1.5rem 0 1.5rem 0;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid rgb(175, 166, 166);
        box-shadow: 0 1px 6px rgba(138, 134, 134, 0.8);
        font-size: 1.2rem;
        color:rgb(63, 57, 49);
        transition: 0.5s;
        cursor: pointer;
        margin-top: -25rem;
      }

      .menuIcon.show {
        display: none;
      }

      .divisions {
        margin-top: -20rem;
      }

      .divisions.show, .about.show, .startPage.show  {
        margin-top: 5rem;
        position: relative;
      top: 8rem;
      }

      .startPage, .about {
        margin-top: -20rem;
      }

      .basketContainer {
        position: absolute;
        right: 1rem;
      }

      .divisionView {
        display: none;
        position: absolute;
        top: 15rem;
        justify-content: center;
    }
    
    .divisionView.show {
        display: flex;
        top: 22rem;
    }
    

    .basketListElement {
      margin-top: 1rem;
      font-size: 1.2rem;
      width: 35rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .basketListElementName {
      margin-bottom: 1rem;
    }

    .basketPrice {
      margin-top: 1rem;
    }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  white-space:pre-line;  
  position:relative;
  top:-7px;
  vertical-align: middle;
}
::-moz-placeholder { /* Firefox 19+ */
     white-space:pre-line;  
  position:relative;
  top:-7px;
  vertical-align: middle;
}
:-ms-input-placeholder { /* IE 10+ */
    white-space:pre-line;  
  position:relative;
  top:-7px;
  vertical-align: middle;
}
:-moz-placeholder { /* Firefox 18- */
     white-space:pre-line;  
  position:relative;
  top:-7px;
  vertical-align: middle;
}

.contact {
  font-style: italic;
  font-weight: bolder;
}

.discount {
  position: absolute;
  bottom: 7rem;
  right: 0.5rem;
  background-color: #faa6af;
  font-weight: bold;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  transform: scale(1);
}

.discountPrice {
  text-decoration: line-through;
  font-size: 1rem;
  margin-left: 5px;
}

.productPrice30 {
  position: absolute;
  bottom: 1px;
  right: 1rem;
  font-size: 0.7rem;
}

.ebookInfo {
  margin: 1rem 0 3rem 0;
  border: 2px solid #beaeae;
  padding: 1rem;
  width: 82%;
  text-align: center;
  border-radius: 2rem;
  background-color: #fff9f4;
  font-size: 1.2rem;
}